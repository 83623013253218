(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbFaq.controller:FaqDialogCtrl
   *
   * @description
   *
   */
  angular
    .module('wbFaq')
    .controller('FaqDialogCtrl', FaqDialogCtrl);

  function FaqDialogCtrl($mdDialog) {
    var vm = this;

    vm.closeDialog = function closeDialog() {
      $mdDialog.hide();
    };
  }
}());
